import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h3
      style={{width: '100%', minHeight: 'calc(100vh - 208px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >找不到页面...</h3>
  </Layout>
)

export default NotFoundPage
